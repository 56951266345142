<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta">
          <b-row align-v="center">
            <b-col>
              <h1 class="mb-2">
                <feather-icon icon="UsersIcon" size="30" class="mr-50" />
                {{ id ? 'Editar' : 'Adicionar' }} Profissional
              </h1>
              <!-- <h4 class="mt-1">Selecione um paciente:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <h4 class="mb-2">Dados do Profissional</h4>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="chat-app-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row align-v="start">
            <b-col md="6">
              <b-form-group label="Primeiro Nome">
                <validation-provider
                  #default="{ errors }"
                  name="Primeiro Nome"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.firstName"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Último Nome">
                <validation-provider
                  #default="{ errors }"
                  name="Último Nome"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.lastName"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Gênero">
                <validation-provider
                  #default="{ errors }"
                  name="Gênero"
                  vid="gender"
                  rules="required"
                >
                  <v-select
                    v-model="form.gender"
                    :options="genderOptions"
                    item-text="name"
                    item-value="id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Idade">
                <b-form-input
                  type="number"
                  v-model="form.age"
                  placeholder="00"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <!-- TODO - Colocar campo de calendário -->

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Data de nascimento"
                vid="birthDate"
                rules="required"
              >
                <b-form-group label="Data de Nascimento">
                  <flat-pickr
                    v-model="form.birthDate"
                    class="form-control"
                    :config="configFlatPickr"
                    placeholder="DD-MM-AAAA"
                    :required="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group label="Nacionalidade">
                <b-form-input
                  v-model="form.nationality"
                  placeholder="Digite aqui"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Estado Civil">
                <validation-provider
                  #default="{ errors }"
                  name="Estado Civil"
                  vid="marital"
                  rules="required"
                >
                  <v-select
                    v-model="form.maritalStatus"
                    :options="maritalStatus"
                    item-text="name"
                    item-value="id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="CPF"
                vid="cpf"
                rules="required"
              >
                <b-form-group label="CPF">
                  <b-form-input v-model="form.cpf" placeholder="Digite aqui" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Qual é o campo de cns? -->
            <b-col md="6">
              <b-form-group label="CNES">
                <b-form-input v-model="form.cns" placeholder="Digite aqui" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Endereço"
                vid="address"
                rules="required"
              >
                <b-form-group label="Endereço">
                  <b-form-input
                    v-model="form.person.address"
                    placeholder="Rua, Avenida, número"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="CEP"
                vid="postalCode"
                rules="required"
              >
                <b-form-group>
                  <b-form-input v-model="form.person.postalCode" placeholder="CEP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Bairro"
                vid="neighborhood"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="form.person.neighborhood"
                    placeholder="Bairro"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Cidade"
                vid="city"
                rules="required"
              >
                <b-form-group>
                  <b-form-input v-model="form.person.city" placeholder="Cidade" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Estado"
                vid="state"
                rules="required"
              >
                <b-form-group label="Estado">
                  <b-form-input
                    v-model="form.person.state"
                    placeholder="Digite aqui"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- TODO - Fazer o get de escolaridades -->
            <b-col md="6">
              <b-form-group label="Formação">
                <v-select
                  :options="schoolingOptions"
                  item-text="name"
                  item-value="id"
                  v-model="form.schooling"
                  label="name"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <validation-provider
                #default="{ errors }"
                name="Contato"
                vid="contato"
                rules="required"
              >
                <b-form-group label="Contato">
                  <b-form-input v-model="form.person.phoneNumber" placeholder="Fixo" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="5">
              <validation-provider
                #default="{ errors }"
                name="Celular"
                vid="celular"
                rules="required"
              >
                <b-form-group label="Celular">
                  <b-form-input
                    v-model="form.person.mobilePhone"
                    placeholder="Celular"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-group label="Email">
                  <b-form-input
                    v-model="form.person.email"
                    type="email"
                    placeholder="exemplo@exemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <h4 class="mb-2">Licença na plataforma</h4>

      <b-form class="chat-app-form">
        <b-row align-v="start">
          <b-col md="3">
            <b-form-checkbox
              v-model="form.visitedPsy"
              name="check-button"
              switch
              inline
            >
              Licença ativa
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <h4 class="mb-2">Redefinir Senha</h4>

     
      
     

        <!-- buttons -->
        <b-col cols="12">
          <p>Clique para enviar link de redefinição para o email do usuário</p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            
            
          >
            Enviar E-mail de Redefinição
          </b-button>
          
        </b-col>
        <!--/ buttons -->
    </b-card>
  </div>
</template>

<script>
import schoolingService from '@/services/schoolingService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import {
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText,
  BFormCheckbox,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { hideSpinner, showSpinner } from '@/services/spinService'
import professionalService from '@/services/professionalService'
import maritalService from '@/services/maritalService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import DependentesFormModal from '@/components/modals/DependentesFormModal.vue'
import { cloneObject } from '@/@core/utils/utils'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
export default {
  components: {
    DependentesFormModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BCardText,
    BFormTextarea,
    BInputGroup,
    BFormCheckbox,
    flatPickr,
  },
  props: ['id'],
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    showDependentesModal: true,
    required,
    sexos: ['Masculino', 'Feminino', 'Outros'],
    schoolingOptions: [],
    maritalStatus: [],
    genderOptions: [
      { name: 'Masculino', id: 'M' },
      { name: 'Feminino', id: 'F' },
    ],
    meses: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    form: {
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      id: null,
      address: null,
      neighborhood: null,
      city: null,
      state: null,
      postalCode: null,
      phoneNumber: null,
      mobilePhone: null,
      email: null,
      status: null,
      login: null,
      cpf: null,
      professionalBoardNumber: null,
      firstName: null,
      lastName: null,
      cnpj: null,
      businessName: null,
      cnes: null,
      isLegalPerson: null,
    },
  }),
  methods: {
    prepareRequest(obj) {
      return {
        ...obj,
        gender: obj.gender.id,
        birthDate: `${obj.birthDate.split('/').reverse().join('-')} 00:00`,
      }
    },
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        // showSpinner()
        professionalService[this.id ? 'update' : 'create'](this.prepareRequest(cloneObject(this.form)))
          .then(res => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (!this.id) {
              console.log(res)
              this.$router.push({ name: 'pacientes' })
            }
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: 'Verifique os campos!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async getSchoolings() {
      this.schoolingOptions = await schoolingService.getAll()
    },
    async getMaritals() {
      this.maritalStatus = await maritalService.getAll()
    },
    async getPatientById() {
      const profissional = await professionalService.getById(this.id)
      profissional.gender = this.genderOptions.find(
        gender => gender.id === profissional.gender
      )
      this.form = profissional
    },
  },
  computed: {
    dias() {
      return Object.keys(new Array(31).fill(0)).map(i => parseInt(i) + 1)
    },
    anos() {
      return Object.keys(new Array(150).fill(0)).map(i => parseInt(i) + 1852)
    },
  },
  mounted() {
    this.getSchoolings()
    this.getMaritals()
    console.log(this.id)
    if (this.id) {
      this.getPatientById()
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
