var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', {
    staticClass: "mb-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UsersIcon",
      "size": "30"
    }
  }), _vm._v(" " + _vm._s(_vm.id ? 'Editar' : 'Adicionar') + " Profissional ")], 1)])], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Dados do Profissional")]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          staticClass: "chat-app-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('b-row', {
          attrs: {
            "align-v": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Primeiro Nome"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Primeiro Nome",
            "vid": "firstName",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "firstName", $$v);
                  },
                  expression: "form.firstName"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Último Nome"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Último Nome",
            "vid": "lastName",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "lastName", $$v);
                  },
                  expression: "form.lastName"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Gênero"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Gênero",
            "vid": "gender",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('v-select', {
                attrs: {
                  "options": _vm.genderOptions,
                  "item-text": "name",
                  "item-value": "id",
                  "label": "name"
                },
                model: {
                  value: _vm.form.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "gender", $$v);
                  },
                  expression: "form.gender"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Idade"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "00",
            "disabled": true
          },
          model: {
            value: _vm.form.age,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "age", $$v);
            },
            expression: "form.age"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Data de nascimento",
            "vid": "birthDate",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Data de Nascimento"
                }
              }, [_c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "config": _vm.configFlatPickr,
                  "placeholder": "DD-MM-AAAA",
                  "required": true
                },
                model: {
                  value: _vm.form.birthDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "birthDate", $$v);
                  },
                  expression: "form.birthDate"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nacionalidade"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.nationality,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nationality", $$v);
            },
            expression: "form.nationality"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Estado Civil"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Estado Civil",
            "vid": "marital",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('v-select', {
                attrs: {
                  "options": _vm.maritalStatus,
                  "item-text": "name",
                  "item-value": "id",
                  "label": "name"
                },
                model: {
                  value: _vm.form.maritalStatus,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "maritalStatus", $$v);
                  },
                  expression: "form.maritalStatus"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CPF",
            "vid": "cpf",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "CPF"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.cpf,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "cpf", $$v);
                  },
                  expression: "form.cpf"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "CNES"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Digite aqui"
          },
          model: {
            value: _vm.form.cns,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cns", $$v);
            },
            expression: "form.cns"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Endereço",
            "vid": "address",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Endereço"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Rua, Avenida, número"
                },
                model: {
                  value: _vm.form.person.address,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "address", $$v);
                  },
                  expression: "form.person.address"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "CEP",
            "vid": "postalCode",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "CEP"
                },
                model: {
                  value: _vm.form.person.postalCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "postalCode", $$v);
                  },
                  expression: "form.person.postalCode"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Bairro",
            "vid": "neighborhood",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Bairro"
                },
                model: {
                  value: _vm.form.person.neighborhood,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "neighborhood", $$v);
                  },
                  expression: "form.person.neighborhood"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Cidade",
            "vid": "city",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('b-form-group', [_c('b-form-input', {
                attrs: {
                  "placeholder": "Cidade"
                },
                model: {
                  value: _vm.form.person.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "city", $$v);
                  },
                  expression: "form.person.city"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Estado",
            "vid": "state",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var errors = _ref12.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Estado"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Digite aqui"
                },
                model: {
                  value: _vm.form.person.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "state", $$v);
                  },
                  expression: "form.person.state"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Formação"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.schoolingOptions,
            "item-text": "name",
            "item-value": "id",
            "label": "name"
          },
          model: {
            value: _vm.form.schooling,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "schooling", $$v);
            },
            expression: "form.schooling"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Contato",
            "vid": "contato",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Contato"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Fixo"
                },
                model: {
                  value: _vm.form.person.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "phoneNumber", $$v);
                  },
                  expression: "form.person.phoneNumber"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Celular",
            "vid": "celular",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var errors = _ref14.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Celular"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Celular"
                },
                model: {
                  value: _vm.form.person.mobilePhone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "mobilePhone", $$v);
                  },
                  expression: "form.person.mobilePhone"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "vid": "email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Email"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "email",
                  "placeholder": "exemplo@exemplo.com"
                },
                model: {
                  value: _vm.form.person.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.person, "email", $$v);
                  },
                  expression: "form.person.email"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Licença na plataforma")]), _c('b-form', {
    staticClass: "chat-app-form"
  }, [_c('b-row', {
    attrs: {
      "align-v": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.visitedPsy,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "visitedPsy", $$v);
      },
      expression: "form.visitedPsy"
    }
  }, [_vm._v(" Licença ativa ")])], 1)], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Redefinir Senha")]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("Clique para enviar link de redefinição para o email do usuário")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Enviar E-mail de Redefinição ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }